form {
  /* width: 30vw; */
  min-width: 500px;
  align-self: center;
  /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07); */
  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripeButton {
  background: linear-gradient(
    180deg,
    rgba(45, 208, 255, 0.5) 0%,
    rgba(24, 145, 180, 0.5) 100%
  ) !important;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  border-radius: 12px;
  border: 0;
  padding: 12px 56px;
  font-size: 16px !important;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  display: "flex";
  flex-direction: column;
  align-items: center;
}
#button-text {
  font-size: 16px !important;
  font-family: "Poppins", sans-serif;
}
.stripeButton:hover {
  filter: contrast(115%);
}

.stripeButton:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #ed1380;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #ed1380;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.p-FieldLabel {
  color: #fff !important;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding-bottom: 6px;
  text-transform: uppercase;
}

.Input, .p-FauxInput {
  background-color: #252525 !important;
  color: #ffffff !important;
}



@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  #payment-form {
    width: 100% !important;
    min-width: initial;
    padding: 0px !important;
  }

}
